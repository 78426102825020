// Here you can add other styles
.app-header.navbar {
  z-index: 1 !important;
}

.app-body {
  overflow: visible;
  .main {
    min-height: calc(100vh - calc(var(--footer-height) + var(--header-height)));
  }
}

.sidebar {
  background-color: #4c4c4c;
  .nav-link {
    display: flex;
    align-items: center;
    &.active {
      background-color: #353535;
      i {
        color: #d14f27 !important;
      }
    }

    &:hover {
      background-color: #d14f27 !important;

      &.active i {
        color: #fff !important;
      }
    }
  }
}
.breadcrumb-item {
  a {
    color: #d14f27 !important;
  }
}

@media screen and (min-width: 992px) {
  .sidebar-minimized .sidebar .nav > .nav-item {
    > .nav-link {
      min-height: 45px;
      font-size: 0px;
      padding-left: 16px;
    }

    .nav-dropdown-items .nav-item .nav-link {
      padding-left: 1rem;
    }

    .nav-icon {
      width: unset;
      margin-right: 0px;
    }

    &:hover {
      background-color: #d14f27;

      > .nav-link {
        background-color: #d14f27;
        font-size: inherit;

        .nav-icon {
          width: unset;
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.app-footer {
  a {
    color: #d14f27;
  }
}

.custom-login {
  flex-direction: column;
  img {
    margin-top: auto;
    margin-bottom: 50px;
  }
  .container {
    margin-bottom: auto;
  }
}

.btn.btn-primary {
  background-color: #d14f27;
  border-color: #d14f2760;

  &:hover {
    background-color: #bd4622;
    border-color: #d14f2760;
  }

  &.btn-primary:not(:disabled):not(.disabled):active {
    background-color: #d14f27;
    border-color: #d14f2760;
    box-shadow: 0 0 0 0.2rem #d14f274f;
  }

  &:focus,
  &.btn-primary.focus {
    box-shadow: 0 0 0 0.2rem #d14f274f;
  }
}

.react-bs-table-tool-bar {
  margin-bottom: 10px !important;
  input {
    margin-right: 15px;
  }

  .react-bs-table-search-clear-btn {
    padding: 0 !important;
    min-width: 70px;
    min-height: 28px;
  }
}

.react-bootstrap-table-page-btns-ul.pagination {
  a {
    color: #d14f27;
  }
  .active.page-item {
    a {
      background-color: #d14f27 !important;
      border-color: #d14f27;
    }
  }
}
.vertical-margin {
  margin: 16px 0;
}
.custom-form {
  .btn-row {
    button {
      margin-right: 15px !important;
    }
  }
  .button {
    background-color: #d14f27 !important;
    color: #fff;
    border-width: 0;
    border-color: none;
    box-shadow: none;
    text-shadow: none !important;
    border-radius: 5px;
    &:hover {
      background: #df4236 !important;
    }
  }

  .standalone-btn {
    min-width: 140px;
    min-height: 34px;
  }

  .custom-save-btn {
    margin: 30px 0;
    min-width: 120px;
    min-height: 34px;
    background-color: #66d899 !important;
    &:hover {
      background-color: #33ca77 !important;
    }
    &:focus {
      outline: none;
      top: 1px;
    }
  }

  .custom-cancel-btn {
    margin: 30px 0;
    min-width: 120px;
    min-height: 34px;
    background-color: #b8b8b8 !important;
    &:hover {
      background-color: #858585 !important;
    }
    &:focus {
      outline: none;
      top: 1px;
    }
  }

  input[type='checkbox'] {
    background-color: #d14f27 !important;
  }

  .form-title {
    align-self: start;
    margin: 20px 0 18px 0;
    &:first-of-type {
      margin-top: 0;
    }
  }
  label {
    margin: 0 10px;
    color: #4d4d4d;

    &.field-label {
      margin: 12px 0;
      white-space: nowrap;

      .required-indicator {
        margin-left: 2px;
        color: var(--danger);
      }
    }
  }
  p {
    color: #575757;
  }

  [class*='col-'] {
    input[type='text'],
    textarea {
      width: 100%;
      margin: 0;
    }
    p {
      white-space: nowrap;
    }
  }

  textarea {
    background-color: #fff !important;
    width: 100%;
    min-height: 80px;
  }

  .separator {
    margin: 0 13px;
  }

  .input-md {
    width: 25vw;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: start;
    [class*='col-'] {
      padding: 0;
    }

    &-column {
      display: flex;
      flex-direction: column;
      [class*='col-'] {
        padding: 0;
      }
    }

    .button {
      margin-right: 10px;
    }
  }

  .justify-end {
    margin-left: auto;
    label {
      white-space: nowrap;
      &:last-child {
        margin-right: 1px;
      }
    }
    input[type='text'] {
      width: auto !important;
    }
  }

  .text-end {
    text-align: end;
  }

  .line-margin {
    margin: 12px 0;
  }

  .subline-spacing {
    margin: 8px 0;
  }

  .align-start {
    align-items: start;
  }

  .stretch {
    width: 100% !important;
  }
}

.custom-panel {
  margin-top: 20px;
  .panel {
    &-header {
      padding: 8px 15px;
      background-color: #d14f27;
      p {
        margin: 0;
        color: #fff;
      }
    }
    &-content {
      padding: 8px 15px;
      .subtitle {
        display: flex;
        p {
          margin-right: 20px;
          b {
            margin-right: 7px;
            font-weight: 700;
          }
        }
      }
      [class*='col-'] {
        input[type='text'],
        textarea {
          width: 100%;
          margin: 0;
        }
        p {
          white-space: nowrap;
        }
      }
    }
  }
}

.checkbox-label {
  input {
    margin-right: 5px;
  }
}

.spacing-lg {
  gap: 1rem;
}

.spacing-md {
  gap: 0.5rem;
}

:root {
  --primary: #d14f27;
}

@import 'vendors/dialog';
