@import "~react-bootstrap-table/dist/react-bootstrap-table.min.css";

@mixin transition {
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@mixin onHoverEffect($color) {
  @include transition();
  &:hover {
    @include transition();
    background-color: darken($color, 10%) !important;
  }
}
.device-info {
  &-container {
    .custom-dropdown {
      .dropdown-toggle {
        background-color: #60a9e4 !important;
        box-shadow: none !important;
        color: #fff;
        border-width: 0;
        font-size: 0.8rem !important;
        outline: none;
        @include onHoverEffect(#60a9e4);
      }
      display: flex;
    }
    .row {
      align-items: center;
    }
    .page-header {
      align-items: center;
      p {
        margin: auto 10px auto 0;
      }
      h1 {
        margin: 0;
        font-size: 1.3rem;
      }

      button {
        box-shadow: none;
        outline: none;
        background-color: #ededed;
        color: #555;
        font-weight: 700;
        font-size: 0.9rem;
        padding: 10px 20px;
        border-radius: 4px;
        border-width: 0;
        margin-right: 10px;
        @include onHoverEffect(#ededed);
        &:last-of-type {
          margin-right: 0;
        }
      }

      .primary-btn {
        color: #fff;
        background-color: var(--primary);
        @include onHoverEffect(#d14f27);
      }
    }
    .table-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        padding-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #9b9b9b;
        background-color: transparent;
        &:last-child {
          padding: 0;
        }

        border: none;
        outline: none;
        box-shadow: none;
        @include transition();
        &:hover {
          @include transition();
          color: #555;
          cursor: pointer;
        }
      }
    }
  }
}

.flex {
  display: flex;
}

.justify-end {
  margin-left: auto !important;
}

[class*="table-btn"] {
  border-radius: 4px !important;
  font-size: 0.8rem !important;
  color: #fff;
  border-width: 0;
  box-shadow: none !important;
  outline: 0px !important;
  padding: 6px 10px !important;
  margin-right: 10px;
  i {
    display: none !important;
  }
}

.table-btn {
  &-delete {
    background-color: #ca2f2f !important;
    @include onHoverEffect(#ca2f2f);
    &:hover {
      color: #fff !important;
    }
  }
  &-default {
    background-color: #afafaf !important;
    @include onHoverEffect(#afafaf);
  }
}
